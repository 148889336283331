import React, { Component, Fragment, useState } from 'react';
import { Container, Row, Col, Card, Button, Popover, PopoverContent, PopoverTitle, Overlay, 
  OverlayTrigger } from 'react-bootstrap';
import { BrowserRouter as Router } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { ContestCountdown } from '../component';
import cat from '../assets/cat-trophy.svg';
import dog from '../assets/dog-trophy.svg';

const data = [
  {
    name: 'runway ready',
    description: 'These are the style icons, the pets that wore it best. Show us your BFF in their favourite sweater, bow tie, booties etc.',
    icon: './img/runway.svg'
  },
  {
    name: 'best in slow-mo',
    description: 'Running, jumping, dancing, or sliding. Show us your best slow-mo capture of your pets.',
    icon: './img/slow.svg'
  },
  {
    name: 'top snoozer',
    description: 'Our pets love to sleep in the funniest positions. Show us their best or funniest pose while napping.',
    icon: './img/snoozer.svg'
  },
  {
    name: 'co-worker of the year',
    description: 'If you’ve been working from home this year, we want to see how your pet helped you make it through the lockdowns.',
    icon: './img/coworker.svg'
  },
  {
    name: 'if I fits, I sits',
    description: 'A box? A vase? A teeny, tiny bed? Our pets aren’t picky, if they fit, they sit. Submit your pets funniest or best contortions into places that look less than comfortable.',
    icon: './img/sit.svg'
  },
  {
    name: 'treat master',
    description: 'Treats, they smell good, and they taste even better. We want to see what lengths your pet will go to for a reward.',
    icon: './img/master.svg'
  },
  {
    name: 'fastest zoomies',
    description: 'They zip to your left, they zip to your right. When the zoomies hit, they’re out of sight. Show us your pet’s best zoomies.',
    icon: './img/zoomies.svg'
  }
 
];

const awards = [
  cat,
  dog
];



data.forEach(function(el,i){
    el.pop = ( <Popover id="popover" key={el.id}>
    <Popover.Content>
      <h3 className="popover-title">{el.name}</h3>
      <p>{el.description}</p>
      <Button className="popover-button disabled" variant="primary" href={"/submit/"+el.name}>SUBMISSION PERIOD CLOSED</Button>
    </Popover.Content>
</Popover> );
});

const topdogpop = ( <Popover id="popover" key="8">
<Popover.Content>
  <h3 className="popover-title">Top Dog</h3>
  <p>
Proudly sponsored by the Ontario SPCA and Humane Society, this award will be
chosen by the judges and is not submittable.<br />
Awarded to the dog that rates the highest over all categories. Maybe they’re the cutest, the funniest or just the most unique. This is The Zoomies Awards Top Dog.
  </p>
</Popover.Content>
</Popover> );
const topcatpop = ( <Popover id="popover" key="9">
<Popover.Content>
  <h3 className="popover-title">Top Cat</h3>
  <p>
This award will be chosen by the judges and is not submittable.<br />
Awarded to the cat that rates the highest over all categories. Maybe they’re the cutest, the funniest or just the most unique. This is The Zoomies Awards Top Cat.  </p>
</Popover.Content>
</Popover> );

class Categories extends Component{
  state = {
    isActive: -1,
  } 

  handleToggle = (index,togglingOn) => {
    const currentlyActive = this.state.isActive;
    if(currentlyActive===index) {
      this.setState({isActive: -1});
    } else {
      if (togglingOn) this.setState({isActive: index});
    }
  };

    componentDidMount(){
      
    }

    render(){
        const award = awards[Math.floor(Math.random()*awards.length)];
        return(

          <Fragment>
            <div className="header-text-wrap categories text-center">
             <Container>
                <div className="sparkle-wrap">
                    <img id="star1" src='/img/star1.svg' className="d-block d-md-none" alt="The Zoomies Awards" />
                    <h2 className="section-title py-5">A VIRTUAL PET AWARD SHOW FOR YOUR BEST FRIEND</h2> 
                    <img id="star2" className="d-block d-md-none" src='/img/star2.svg' alt="The Zoomies Awards" />
                </div>
                <div className="countdown pb-5">
                  {/* <h2 className="section-title d-block d-md-none">the show starts in</h2>
                  <ContestCountdown /> */}
                  <span>Thanks for all your nominations!</span><br /><br />
                  <span>Unfortunately, our submission period is
                    now closed. But we go live on October 7,
                    2021, at 7 PM EST to announce the
                    winners at a virtual live-stream event.
                    Tune in to attend The Zoomies Awards!
                  </span>
                  <Button href="/" variant="secondary" className="mt-5">Watch the event</Button>
                </div>
              </Container>  
            </div>
            
            <Container>
              <Row className="d-block d-md-none">
                <Col>
                  <div className="trophy">
                    <img id="trophy" src={award} alt="The Zoomies Awards Trophy" />
                    <img id="red-star-left" src="/img/red-star-left.svg" alt="The Zoomies Awards" />
                    <img id="red-star-right"  src="/img/red-star-right.svg" alt="The Zoomies Awards" />
                  </div>
                </Col>
              </Row>
              <h2 className="section-title">Choose your category</h2>
              <Row className="text-center"  sm={12} md={3}>
                { data.map( 
                  ({name,description,icon,pop},index) => 
                  
                  <Col sm={12} md={4} key={index} className={(this.state.isActive===index || this.state.isActive===-1 ? "active" : "inactive") + " category-item mb-4"} id={name}>
                    <OverlayTrigger trigger="click" rootClose placement="bottom" className="custom-tooltip" overlay={pop} onToggle={e=>this.handleToggle(index,e)}>  
                      <div>
                        <img src={icon} alt={name} /> 
                        <h3 className="category-title">{name}</h3>
                      </div>
                    </OverlayTrigger>              
                  </Col>    
                ) }

                  <Col className={(this.state.isActive===7 || this.state.isActive===-1 ? "active" : "inactive") + " d-none d-md-block awards category-item"} sm={12} md={4}>
                    <OverlayTrigger trigger="click" onHide rootClose tabindex="0" placement="bottom" overlay={topdogpop} onToggle={e=>this.handleToggle(7,e)}>
                      <div className="icon-wrap">
                        <div >
                          <img src='/img/top-dog-web.svg' className="category-image" alt="" /> 
                        </div>
                        <div className="icon-bg dog"></div>
                        <h3 className="category-title">Top Dog</h3>
                      </div>
                    </OverlayTrigger>          
                  </Col> 
                  <Col className={(this.state.isActive===8 || this.state.isActive===-1 ? "active" : "inactive") + " d-none d-md-block awards category-item"} sm={12} md={4}>
                    <OverlayTrigger trigger="click" onHide rootClose tabindex="0" placement="bottom" overlay={topcatpop} onToggle={e=>this.handleToggle(8,e)}>
                      <div className="icon-wrap">
                        <div >
                          <img src='/img/top-cat-web.svg' className="category-image" alt="" /> 
                        </div>
                        <div className="icon-bg cat"></div>
                        <h3 className="category-title">Top cat</h3>
                      </div>
                    </OverlayTrigger>              
                  </Col>
              </Row>
            </Container>
            <div className="d-block d-md-none">
              <img src='/img/oval-footer.svg' className="ellipsis-footer" alt="red half circle" />
              <section className="additional-awards">
                <Container>
                  <h2 className="section-title">top prizes</h2>
                  <Row>
                    <Col className={(this.state.isActive===7 || this.state.isActive===-1 ? "active" : "inactive") + " d-block d-md-none category-item"} sm={12}>
                      <OverlayTrigger trigger="click" onHide rootClose tabindex="0" placement="bottom" overlay={topdogpop} onToggle={e=>this.handleToggle(7)}>
                        <div className="icon-wrap">
                          <div >
                            <img src='/img/top-dog-web.svg' className="category-image" alt="" /> 
                          </div>
                          <div className="icon-bg dog"></div>
                          <h3 className="category-title">Top Dog</h3>
                        </div>
                      </OverlayTrigger>          
                    </Col> 
                    <Col className={(this.state.isActive===8 || this.state.isActive===-1 ? "active" : "inactive") + " d-block d-md-none category-item"} sm={12}>
                      <OverlayTrigger trigger="click" onHide rootClose tabindex="0" placement="bottom" overlay={topcatpop} onToggle={e=>this.handleToggle(8)}>
                        <div className="icon-wrap">
                          <div >
                            <img src='/img/top-cat-web.svg' className="category-image" alt="" /> 
                          </div>
                          <div className="icon-bg cat"></div>
                          <h3 className="category-title">Top cat</h3>
                        </div>
                      </OverlayTrigger>              
                    </Col>
                  </Row>  
                </Container>
              </section>
            </div>
          </Fragment>

        );
    }
}

export default Categories;
