import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';


class FullLegalCopy extends Component{

    componentDidMount(){
        
    }

    render(){
        return(
            <Fragment>
                <section className="full-legal">
                    <Container>
                        <h1 className="section-title">THIS CONTEST IS OPEN TO CANADIAN RESIDENTS ONLY (EXCLUDING QUEBEC) AND IS GOVERNED BY CANADIAN LAW</h1>
                        <Row>
                          <Col>
                          <h3>1. KEY DATES:</h3>
                          <p>The Zoomies Awards Contest (the “Contest”) begins on Tuesday, September 7,, 2021 at 12:00:00 a.m. Eastern Time (“ET”) and ends on September 20,, 2021 at 11:59:59 p.m. ET (the “Contest Period”).</p>
                            <h3>2. ELIGIBILITY:</h3>
                            <p>Contest is open to residents of Canada (excluding Quebec), and who are 18 years of age or older at the time of entry; except employees,
representatives or agents (and those with whom such persons are living, whether related or not) of Canadian Tire Corporation, Limited (the
“Sponsor”), and each of their respective parent companies, subsidiaries, affiliates, related companies, prize suppliers, dealers,
advertising/promotion agencies and any other individual(s), entity or entities involved in the development, production, implementation,
administration, judging or fulfillment of the Contest (collectively, the “Contest Parties”). This Contest is in no way sponsored, endorsed or
administered by, or associated with, Facebook, Inc., Instagram, Inc. or TikTok Pte. Ltd. Any questions, comments or complaints must be directed
to the Sponsor, and not to Facebook, Inc., Instagram, Inc. or TikTok Pte. Ltd.
                            </p>
                            <h3>3. AGREEMENT TO BE LEGALLY BOUND BY RULES:</h3>
                            <p>By participating in this Contest, you are signifying your agreement that you have read and agree to be legally bound by these Rules.
                            </p>
                            <h3>4. ENTRY CATEGORIES:</h3>
                            <p>For the purposes of this Contest, there will be seven (7) categories (each, a “Category” collectively, the “Categories”) as follows: </p>
                            <Table striped bordered size="sm">
                                <thead>
                                    <tr>
                                        <th>Entry Categories</th>
                                        <th>Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Runway Ready</td>
                                        <td>These are the style icons, the pets that wore it best. Show us your BFF in their

favourite sweater, bow tie, booties etc.</td>
                                    </tr>
                                    <tr>
                                        <td>Best in Slow-mo</td>
                                        <td>Running, jumping, dancing, or sliding. Show us your best slow-mo capture of your

cat or dog.</td>
                                    </tr>
                                    <tr>
                                        <td>Top Snoozer</td>
                                        <td>Our pets love to contort into the funniest positions during their nap time. Submit

your favourite position of theirs.</td>
                                    </tr>
                                    <tr>
                                        <td>Co-worker of the Year</td>
                                        <td>If you’ve been working from home this year, we want to see how your pet helped.
Maybe they kept your laptop warm, brought you a snack, or gave you a toy when
you needed it.</td>
                                    </tr>
                                    <tr>
                                        <td>If I fits, I sits.</td>
                                        <td>A box? A vase? A teeny, tiny bed? Our pets aren’t picky, if they fit, they sit.
Submit your pets funniest or best contortions into places that look less than
comfortable.</td>
                                    </tr>
                                    <tr>
                                        <td>Treat Master</td>
                                        <td>If your fur-bestie is a treat lover and they know it, we want to see it! Show us what

they’ll do (or how they look) when they’re looking for a treat.</td>
                                    </tr>
                                    <tr>
                                        <td>Fastest Zoomies</td>
                                        <td>They zip to your left, they zip to your right. When the zoomies hit, they’re out of

sight. Show us your pets best zoomies.</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <p>Each eligible entrant must determine which Category or Categories they wish to enter at the time of entry. Eligible entrants can only enter one (1)
submission per Category. 
                            </p>
                            <h3>5. HOW TO ENTER:</h3>
                            <p>NO PURCHASE NECESSARY. MAKING A PURCHASE WILL NOT INCREASE OR OTHERWISE IMPACT YOUR CHANCES OF
WINNING IN ANY WAY.<br />
                        Each eligible entrant will be permitted to submit one (1) video or one (1) photo submission that depicts, or otherwise reflects the Category entered
(described above in Rule 4). Files must be submitted in JPEG or MP4 format and must be under 20mb. Next, the eligible entrant must submit
their submission via www.thezoomies.ca (the “Website”) to be eligible to earn one (1) entry (each, an “Entry” collectively, the “Entries”) per
Category entered, for a maximum of seven (7) Entries.
                            </p>
                            <p>To be eligible, all materials associated with an Entry (collectively, the “Entry Materials”) must: (i) be submitted and received in accordance
with these Rules during the Contest Period; (ii) reflect the Category that was selected at the time of entry and otherwise meet all applicable
requirements for the submission, including format and size limitations; and (iii) be in accordance with these Rules, including, but not limited to,
the Submission Requirements listed below in Rule 8 (all as determined by Sponsor in its sole and absolute discretion). 
                            </p>
                            <h3>6. ADDITIONAL ENTRY RULES:</h3>
                            <p>The maximum number of Entries that any eligible entrant can have in this Contest is seven (7) Entries. If it is discovered by the Sponsor
(using any evidence or other information made available to or otherwise discovered by the Sponsor) that any person has attempted to use multiple
names, multiple identities, multiple email addresses and/or any other means not in keeping with the Sponsor’s interpretation of the letter and/or
spirit of these Rules to enter or otherwise participate in or to disrupt this Contest; then he/she may be disqualified from the Contest in the sole and
absolute discretion of the Sponsor. The Sponsor, Contest Parties, Facebook, Inc., Instagram, Inc., TikTok Pte. Ltd and each of their respective

2
agents, employees, shareholders, officers, directors, members, agents, successors, and assigns (collectively, the “Released Parties”) are not
responsible for, and accept no liability whatsoever in relation to, any late, lost, misdirected, delayed, incomplete or incompatible Entries, Entry
Materials and/or other information (all of which is void). Your Entry Materials (and the Entry associated with your Entry Materials) may be
rejected if, in the sole and absolute discretion of the Sponsor the Entry Materials are not submitted and received in accordance with these Rules
and/or if the Entry Materials are not otherwise in compliance with these Rules (including, but not limited to, the format and size requirements for
the submission and/or the Submission Requirements listed below in Rule 8) (all as determined by Sponsor in its sole and absolute discretion).  </p>
                            <h3>7. VERIFICATION:</h3>
                            <p>All Entries, Entry Materials and entrants are subject to verification at any time and for any reason. The Sponsor reserves the right, in its sole and
absolute discretion, to require proof of identity and/or eligibility (in a form acceptable to the Sponsor – including, without limitation, government
issued photo identification): (i) for the purposes of verifying an individual’s eligibility to participate in this Contest; (ii) for the purposes of
verifying the eligibility and/or legitimacy of any Entry, Entry Materials and/or other information entered (or purportedly entered) for the purposes
of this Contest; and/or (iii) for any other reason the Sponsor deems necessary, in its sole and absolute discretion, for the purposes of administering
this Contest in accordance with the Sponsor’s interpretation of the letter and spirit of these Rules. Failure to provide such proof to the complete
satisfaction of the Sponsor within the timeline specified by the Sponsor may result in disqualification in the sole and absolute discretion of the
Sponsor. The sole determinant of the time for the purposes of this Contest will be the official time keeping device(s) used by the Sponsor.
                            </p>
                            <h3>8. SUBMISSION REQUIREMENTS:</h3>
                            <p>BY SUBMITTING AN ENTRY, EACH ELIGIBLE ENTRANT AGREES THAT THE ENTRY (AND EACH INDIVIDUAL COMPONENT
THEREOF – INCLUDING, WITHOUT LIMITATION, THE ENTRY MATERIALS) COMPLIES WITH ALL CONDITIONS STATED IN
THESE RULES. THE RELEASED PARTIES WILL BEAR NO LIABILITY WHATSOEVER REGARDING: (I) THE USE OF ANY ENTRY
(OR ANY COMPONENT THEREOF – INCLUDING, WITHOUT LIMITATION, THE ENTRY MATERIALS); (II) PARTICIPATION IN
ANY CONTEST-RELATED ACTIVITIES; (III) ANY USE, COLLECTION, STORAGE AND DISCLOSURE OF ANY PERSONAL
INFORMATION; AND/OR (IV) IF DECLARED A WINNER, ANY PRIZE (INCLUDING ANY USE OR MISUSE OF ANY PRIZE). THE
RELEASED PARTIES SHALL BE HELD HARMLESS BY EACH ELIGIBLE ENTRANT IN THE EVENT IT IS DISCOVERED THAT AN
ELIGIBLE ENTRANT HAS DEPARTED FROM OR NOT OTHERWISE FULLY COMPLIED WITH ANY OF THESE RULES. THIS
RELEASE AND INDEMNITY SHALL CONTINUE IN FORCE FOLLOWING THE TERMINATION OF THE CONTEST AND/OR
AWARDING OF ANY PRIZE.</p>
                        <p>By participating in the Contest and submitting an Entry, each eligible entrant hereby warrants and represents that the Entry Materials he/she
submits:</p>
                        <p className="indent">i. are original to him/her and that the eligible entrant has obtained all necessary rights in and to the Entry Materials for the purposes of
entering such Entry Materials in the Contest;
                        ii. do not violate any law, statute, ordinance or regulation;
                        iii. do not contain any reference to or likeness of any identifiable third parties;
                        iv. will not give rise to any claims whatsoever, including, without limitation, claims of infringement, invasion of privacy or publicity, or
infringe on any rights and/or interests of any third party; and
                        v.  are not defamatory, trade libelous, pornographic or obscene, and further that they will not contain, depict, include, discuss or involve,
without limitation, any of the following: nudity; alcohol/drug consumption or smoking; explicit or graphic sexual activity, or sexual
innuendo; crude, vulgar or offensive language and/or symbols; derogatory characterizations of any ethnic, racial, sexual, religious or
other groups (including, without limitation, any competitors of Sponsor); content that endorses, condones and/or discusses any illegal,
inappropriate or risky behaviour or conduct; personal information of individuals, including, without limitation, names, telephone
numbers and addresses (physical or electronic); commercial messages, comparisons or solicitations for products or services other than
products of Sponsor; any identifiable third party products, trade-marks, brands and/or logos, other than those of Sponsor; conduct or
other activities in violation of these Rules; and/or any other materials that are or could be considered inappropriate, unsuitable or
offensive, all as determined by the Sponsor in its sole and absolute discretion.
                        </p>
                            <p>Any Entry Materials that the Sponsor and/or its promotional agency or designated content moderator deems, in its sole and absolute discretion, to
violate the terms and conditions set forth in these Rules are subject to disqualification. The Sponsor reserves the right, in its sole and absolute
discretion at any time and for any reason, to request an eligible entrant to modify, edit and/or re-submit any Entry Materials (or any part thereof)
in order to ensure that the Entry Materials comply with these Rules, or for any other reason. If such an action is necessary at any point in time
(including, but not limited to, during or after the Contest), then the Sponsor reserves the right, in its sole discretion, to take whatever action it
deems necessary based on the circumstances – including, without limitation, disqualifying the Entry Materials (and therefore the corresponding
Entry and/or the associated eligible entrant) – to ensure that the Contest is being conducted in accordance with the Sponsor’s interpretation of the
letter and spirit of these Rules.
                            </p>
                            <h3>9. LICENSE:</h3>
                            <p>By participating in the Contest and submitting an Entry, each eligible entrant hereby: (i) grants to the Sponsor, in perpetuity, a non-exclusive
license to publish, display, reproduce, modify, edit or otherwise use the Entry Materials (and each component thereof), in whole or in part,
associated with his/her Entry for advertising or promoting the Contest or for any other reason; (ii) waives all moral rights in and to the Entry
Materials (and each component thereof) associated with his/her Entry in favour of the Sponsor (and anyone authorized by the Sponsor to use the
Entry Materials); and (iii) agrees to release and hold harmless the Released Parties from and against any and all claims, damages, liabilities, costs,

3
and expenses arising from use of the Entry Materials (and each component thereof), including, without limitation, any claim based on publicity
rights, defamation, invasion of privacy, copyright infringement, trade-mark infringement or any other intellectual property related or other cause
of action whatsoever.</p>
                            <h3>10. THE PRIZES:</h3>
                            <p>One (1) Prize will be awarded for the top scoring (as determined by the Judges, in their sole and absolute discretion) Entry in each Category (as
described above in Rule 4), for a total of seven (7) Prizes (each, a “Prize” collectively, the “Prizes”) available to be won, and consist of the
following: 
                            </p>
                        <Table striped bordered size="sm">
                            <thead>
                                <tr>
                                    <th>Prize</th>
                                    <th>Items</th>
                                    <th>Approximate Retail Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Category Prize (7 available)</td>
                                    <td>One (1) $1,000 Canadian Tire Gift Card* (each)</td>
                                    <td>$7,000 CAD</td>
                                </tr>
                            </tbody>
                        </Table> 
                        <span>*Use of Gift Cards are subject to the terms and conditions of the issuer.</span>
                        <p>Without regard to Category, the highest scoring Entry (as determined by the Judges, in their sole and absolute discretion) associated with a Dog
and the highest scoring Entry (as determined by the Judges, in their sole and absolute discretion) associated with a Cat will also be eligible to win
(subject to compliance with these Rules) the Top Dog and Top Cat Prize, respectively, and each will be awarded an additional $5,000 Canadian
Tire Gift Card*. Odds of being selected as an eligible winner of a Prize depends on the number and calibre of eligible Entries in each Category. 
                        </p>
                        <p>Without regard to Category, 5 (five) additional the highest scoring Entry (as determined by the Judges, in their sole and absolute discretion)
associated with a Dog and the highest scoring Entry (as determined by the Judges, in their sole and absolute discretion) associated with a Cat will
also be eligible to win (subject to compliance with these Rules) the Top Dog and Top Cat Prize, respectively, and each will be awarded an
additional $5,000 Canadian Tire Gift Card*. Odds of being selected as an eligible winner of a Prize depends on the number and calibre of eligible
Entries in each Category.</p>
                        <p>The total Approximate Retail Value of the Prizes is $17,000 CAD.</p>
                        <p>There is a limit of one (1) Prize per person.</p>
                        <p>Without limiting the generality of the foregoing, the following general conditions apply to each Prize: (i) Prize must be accepted as awarded and
is not transferable, assignable and/or convertible to cash (except as may be specifically permitted by Sponsor in its sole and absolute discretion);
(ii) no substitutions are permitted, except at Sponsor’s option; (iii) Prize must be utilized in full on or before the date or dates specified by the
Sponsor (otherwise the Prize may, in the sole and absolute discretion of the Sponsor, be forfeited in its entirety and, if forfeited, nothing will be
substituted in its place); (iv) the costs of everything not specifically and expressly stated above as included in the Prize are the sole and absolute
responsibility of the confirmed winner; (v) if the confirmed winner does not utilize any part(s) of the Prize, then any such part(s) not utilized may,
in the sole and absolute discretion of the Sponsor, be forfeited in their entirety and, if forfeited, nothing will be substituted in their place; (vi)
Sponsor reserves the right at any time to: (a) place reasonable restrictions on the availability or use of the Prize or any component thereof; and/or
(b) substitute the Prize or a component thereof for any reason with a prize or prize component(s) of equal or greater retail value, including,
without limitation, but solely at the Sponsor’s sole discretion, a cash award; (vii) all arrangements relating to the Prize must be made through the
Sponsor or its designated agents; (viii) Sponsor and the other Released Parties will not be responsible in the event any activity is delayed,
cancelled, postponed or rescheduled for any reason whatsoever, including without limitation, by reason of COVID-19; and (ix) by accepting the
Prize, the confirmed winner agrees to waive all recourse against the Sponsor and all of the other Released Parties if the Prize or a component
thereof does not prove satisfactory, either in whole or in part.
                        </p>
                        <p>None of the Released Parties makes any representation or offers any warranty, express or implied, as to the quality or fitness of any Prize
awarded in connection with the Contest. To the fullest extent permitted by applicable law, each confirmed winner of a Prize understands and
acknowledges that he or she may not seek reimbursement or pursue any legal or equitable remedy from either the Sponsor or any of the other
Released Parties should his/her Prize fail to be fit for its purpose or is in any way unsatisfactory.
                        </p>
                        <h3>11. ELIGIBLE WINNER SELECTION PROCESS:</h3>
                        <p>In relation to each Category, a panel of judges (the “Judges”) appointed by the Sponsor will judge each eligible Entry submitted and received in
accordance with these Rules on the basis of the following weighted criteria:
                        </p>
                        <Table striped bordered size="sm">
                            <thead>
                                <tr>
                                    <th>Criteria</th>
                                    <th>Weighting</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1. Category relevance</td>
                                    <td>10 Points</td>
                                </tr>
                                <tr>
                                    <td>2. Humorous</td>
                                    <td>10 Points</td>
                                </tr>
                                <tr>
                                    <td>3. Cuteness</td>
                                    <td>10 Points</td>
                                </tr>
                                <tr>
                                    <td>4. Uniqueness</td>
                                    <td>10 Points</td>
                                </tr>
                                <tr>
                                    <td>Total Score</td>
                                    <td>Maximum 40 Points</td>
                                </tr>
                            </tbody>
                        </Table> 
                        <p>In relation to each Category, each eligible Entry will be given a score (the “Score”) by the Judges. Odds of being selected as an eligible winner
depend on the number and calibre of eligible Entries submitted and received in accordance with these Rules in each Category. 
                        </p>
                        <p>In the event of a tie between two or more eligible Entries in a Category based on Score, the eligible entrant associated with the eligible Entry –
from amongst all such eligible Entries that are tied – with the highest score on Criteria 1 (followed in the event of a further tie by Criteria 2, then
Criteria 3, then Criteria 4) will be selected as the eligible winner for the applicable Category. In the event of an exact tie based on all criteria, a
new panel of judges will be appointed by the Sponsor to break the tie in accordance with the preceding procedure.</p>
                        <p>Judging is scheduled to be completed on Thursday, September 30, 2021 (the <span className="bold">“Selection Date”</span>).</p>
                        <h3>12. ELIGIBLE WINNER NOTIFICATION PROCESS:</h3>
                        <p>The Sponsor or its designated representative will make a minimum of three (3) attempts to contact the eligible entrant associated with each
eligible Entry that has been selected as an eligible winner (using the information provided at the time of entry) within ten (10) business days of
the Selection Date. If the eligible entrant associated with an eligible Entry that has been selected as an eligible winner cannot be contacted as
outlined above, or if there is a return of any notification as undeliverable; then such eligible entrant may, in the sole and absolute discretion of the
Sponsor, be disqualified (and, if disqualified, will forfeit all rights to be a winner and to receive the applicable Prize) and the Sponsor reserves the
right, in its sole and absolute discretion and time permitting, to select an alternate eligible winner based on the next highest Score in accordance
with Rule 11 (in which case the foregoing provisions of this section shall apply to the eligible entrant associated with such newly selected eligible
Entry that has been selected as an eligible winner).
                        </p>
                        <h3>13. ELIGIBLE WINNER CONFIRMATION PROCESS:</h3>
                        <p>NO ONE IS A WINNER UNLESS AND UNTIL THE SPONSOR OFFICIALLY CONFIRMS HIM/HER AS A WINNER IN ACCORDANCE
WITH THESE RULES. BEFORE BEING DECLARED AS A CONFIRMED WINNER, the eligible entrant associated with each eligible Entry
that has been selected as an eligible winner will be required to sign and return within <span className="bold">five (5) business days</span> of notification the Sponsor’s
declaration and release form, which (among other things): (i) confirms compliance with these Rules; (ii) releases the Sponsor and all of the other
Released Parties from any and all liability in connection with this Contest, his/her participation therein and the acceptance, use or misuse of the
applicable Prize or any portion thereof; (iii) assigns all intellectual property, including copyright, in and to his/her Entry Materials in favour of the
Sponsor; (iv) agrees to indemnify the Sponsor and all of the other Released Parties against any and all claims, damages, liabilities, costs, and
expenses arising from use of his/her Entry Materials including, without limitation, any claim that the Entry Materials infringe a proprietary
interest of any third party; and (v) agrees to the publication, reproduction and/or other use of his/her name, city and province/territory of
residence, voice, statements about the Contest and/or photograph or other likeness without further notice or compensation, in any publicity or
advertisement carried out by or on behalf of the Sponsor in any manner whatsoever, including print, broadcast or the internet.
                        </p>
                        <p>If the eligible entrant associated with an eligible Entry that has been selected as an eligible winner: (a) fails to return the properly executed
Contest documents within the specified time; (b) cannot be (or is unwilling to be) a winner for any reason; (c) cannot accept (or is unwilling to
accept) the applicable Prize for any reason; and/or (d) is determined to be in violation of these Rules (all as determined by the Sponsor in its sole
and absolute discretion); then such eligible finalist will be disqualified (and will forfeit all rights to be a winner and to receive the applicable
Prize) and the Sponsor reserves the right, in its sole and absolute discretion and time permitting, to select an alternate eligible winner based on the
next highest Score in accordance with Rule 11 (in which case the foregoing provisions of this section shall apply to the eligible entrant associated
with such newly selected eligible Entry that has been selected as an eligible winner).
                        </p>
                        <h3>14. GENERAL CONDITIONS:</h3>
                        <p>This Contest is subject to all applicable federal, provincial and municipal laws. The decisions of the Sponsor with respect to all aspects of this
Contest are final and binding on eligible entrants without right of appeal. ANYONE DEEMED BY THE SPONSOR TO BE IN VIOLATION
OF THE SPONSOR’S INTERPRETATION OF THE LETTER AND/OR SPIRIT OF THESE RULES FOR ANY REASON IS SUBJECT TO
DISQUALIFICATION IN THE SOLE AND ABSOLUTE DISCRETION OF THE SPONSOR AT ANY TIME. 
                        </p>
                        <p>The Released Parties will not be liable for: (i) any failure of any website or any platform during the Contest; (ii) any technical malfunction or
other problems of any nature whatsoever, including, without limitation, those relating to the telephone network or lines, computer on-line
systems, servers, access providers, computer equipment or software; (iii) the failure of any Entry, Entry Materials, or other information to be
received, captured or recorded for any reason whatsoever, including, but not limited to, technical problems or traffic congestion on the internet or
at any website; (iv) any injury or damage to an eligible entrant’s or any other person’s computer or other device related to or resulting from
participating in the Contest; (v) anyone being incorrectly and/or mistakenly identified as a winner or eligible winner; and/or (vi) any combination
of the above.
                        </p>
                        <p>The Sponsor reserves the right, to withdraw, amend or suspend this Contest (or to amend these Rules) in any way, in the event of any cause
beyond the reasonable control of the Sponsor that interferes with the proper conduct of this Contest as contemplated by these Rules, including,
without limitation, any error, problem, computer virus, bugs, tampering, unauthorized intervention, fraud or failure of any kind whatsoever. Any
attempt to undermine the legitimate operation of this Contest in any way (as determined by Sponsor in its sole and absolute discretion) may be a
violation of criminal and civil laws and should such an attempt be made, the Sponsor reserves the right to seek remedies and damages to the
fullest extent permitted by law. The Sponsor reserves the right to cancel, amend or suspend this Contest, or to amend these Rules, in any way
without prior notice or obligation, in the event of any accident, printing, administrative, or other error of any kind, or for any other reason
whatsoever.
                        </p>
                        <p>
                            By entering this Contest, each eligible entrant expressly consents to the Sponsor, their respective agents and/or representatives, storing, sharing
and using the personal information submitted for the purpose of administering the Contest and in accordance with the Sponsor’s privacy policy
(available at: <a href="https://www.canadiantire.ca/en/customer-service/policies.html#privacypolicy" target="_blank">https://www.canadiantire.ca/en/customer-service/policies.html#privacypolicy</a>). This section does not limit any other consent(s) that
an individual may provide the Sponsor, or others in relation to the collection, use and/or disclosure of their personal information.
                        </p>
                        <p>The Sponsor reserves the right, to adjust any of the dates, timeframes and/or other Contest mechanics stipulated in these Rules, to the extent
deemed necessary by the Sponsor, for purposes of verifying compliance by any eligible entrant, Entry, Entry Materials and/or other information
with these Rules, or as a result of any technical or other problems, or in light of any other circumstances which, in the opinion of the Sponsor, in
its sole and absolute discretion, affect the proper administration of the Contest as contemplated in these Rules, or for any other reason.
                        </p>
                        <p>In the event of any discrepancy or inconsistency between the terms and conditions of these English Rules and disclosures or other statements
contained in any Contest-related materials, including, but not limited to: the French version of these Rules, point of sale, television, print or
online advertising and/or any instructions or interpretations of these Rules given by any representative of the Sponsor; the terms and conditions of
these English Rules shall prevail, govern and control to the fullest extent permitted by law.  
                        </p>
                        <p>The invalidity or unenforceability of any provision of these Rules shall not affect the validity or enforceability of any other provision. In the
event that any provision is determined to be invalid or otherwise unenforceable or illegal, these Rules shall otherwise remain in effect and shall be
construed in accordance with the terms as if the invalid or illegal provision were not contained herein.</p>
                        <p>To the fullest extent permitted by applicable law, all issues and questions concerning the construction, validity, interpretation and enforceability
of these Rules or the rights and obligations of participants, Sponsor or any of the other the Released Parties in connection with the Contest will be
governed by and construed in accordance with the domestic laws of the Province of Ontario and the federal laws of Canada applicable therein,
without giving effect to any choice of law or conflict of law rules or provisions that would cause the application of any other jurisdiction’s laws.
The parties hereby consent to the exclusive jurisdiction and venue of the courts located in Ontario in any action to enforce (or otherwise relating
to) these Rules or relating to this Contest.
                        </p>
                          </Col>
                        </Row>
                    </Container>
                </section> 
            </Fragment>
          );
    }
}

export default FullLegalCopy;

