import React, { Component, Fragment, useState, useRef } from 'react';
import { Container, Row, Col, Button, InputGroup, Form, FormCheck, FormControl,  } from 'react-bootstrap';
import axios from 'axios';
import { HeaderSubmit, EntryCategories, PresentedBy, Footer, 
  LegalCopy, SubmitSuccess, DynamicInput } from '../component';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import ClearIcon from '@material-ui/icons/Clear';
import S3 from 'react-aws-s3';
import config from '../config.json';
var ReactGA = require('react-ga');

const ReactS3Client = new S3(config);

function FullContestRules(props) {

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="legal-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          full contest rules
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="legal-wrapper submit-modal">
            <Container className="legal">
                <Row>
                    <Col>
                        <LegalCopy />
                    </Col>
                </Row> 
            </Container>
        </div>
      </Modal.Body>
    </Modal>
  );
}

class Submit extends Component{

    constructor(props){
      super(props);
      this.fileRef = React.createRef();
      this.namesRef = React.createRef();
      this.termsRef = React.createRef();
    }

    state = {
      modalShow:false,
      name: "",
      nameError: false,
      email: "",
      emailError: false,
      email_confirm: "",
      email_confirmError: false,
      areaCode: "",
      areaCodeError: false,
      phone: "",
      phoneError: false,
      petname1: "",
      petname1Error: false,
      petname2: "",
      petname2Error: false,
      petname3: "",
      petname3Error: false,
      petname4: "",
      petname4Error: false,
      petname5: "",
      petname5Error: false,
      upload: "",
      uploadError: false,
      terms: "",
      termsError: false,
      submissionInProgress: false,
      submissionSuccess: false,
      formVisible: false,
      fileName: '',
      fields: [''],
    }

    inputHandleChange = e => {
      switch (e.target.name) {
        case 'upload':
          const fileSize = e.target.files[0].size;
          const file = Math.round( (fileSize / (1024*1024)) );
          if(e.target.files.length > 0 && file <= 50) {
            this.setState({ fileName: e.target.files[0].name });
          } else {
            alert('You must upload a file under 50MB. \r\n Please check your file size and try again');
            e.target.value = null;
          }
        break;
        default:
          this.setState({ [e.target.name]: e.target.value });
      }

      const fieldError = `${e.target.name}Error`;
      const val = e.target.type=='checkbox' ? e.target.checked : e.target.value;
      this.setState({
          [e.target.name] : val,
          [fieldError] : false
      });
    }

    removeFile = e => {
      this.setState({ 
        upload: "",
        fileName: ""
       });
    }

    formValidation = () => {
      const numNames = this.namesRef.current.state.fields.length; // check how many name fields we are using
      const { email, email_confirm, name, phone, petname1, petname2, petname3, petname4, petname5, upload, terms } = this.state;

      let emailError = false,
        email_confirmError = false,
        nameError = false,
        phoneError = false,
        petname1Error = false,
        petname2Error = false,
        petname3Error = false,
        petname4Error = false,
        petname5Error = false,
        uploadError = false,
        termsError = false

        const re = /\S+@\S+\.\S+/;
        const rp = /^\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

        name ? nameError = false : nameError = true;
        if( re.test(email) ) { emailError = false } else { emailError = true }; 
        email_confirm===email ? email_confirmError = false : email_confirmError = true;
        rp.test(phone) ? phoneError = false : phoneError = true; 
        petname1 ? petname1Error = false : petname1Error = true;
        petname2 || numNames<2 ? petname2Error = false : petname2Error = true; // only count the error if we have at least 2 name fields
        petname3 || numNames<3 ? petname3Error = false : petname3Error = true; // etc.
        petname4 || numNames<4 ? petname4Error = false : petname4Error = true;
        petname5 || numNames<5 ? petname5Error = false : petname5Error = true;
        upload ? uploadError = false : uploadError = true;
        terms ? termsError = false : termsError = true;

        if ( emailError || email_confirmError || nameError || phoneError || petname1Error || petname2Error || petname3Error || petname4Error || petname5Error || uploadError || termsError ){
            this.setState({
                emailError,
                email_confirmError,
                nameError,
                phoneError,
                petname1Error,
                petname2Error,
                petname3Error,
                petname4Error,
                petname5Error,
                uploadError,
                termsError
            })
            return false;
        }
        return true;
    }

    handleSubmit = e => {
        e.preventDefault();

        const { email, email_confirm, name, phone, areaCode, petname1, petname2, petname3, petname4, petname5, upload, terms } = this.state;
        const category = this.props.match.params.name; // pulled from router.
        const formValidation = this.formValidation();
        if ( formValidation === true){
          const file = this.fileRef.current.files[0];

          this.setState({
              submissionInProgress: true
          })

          ReactS3Client
              .uploadFile(file)
              .then(data => {
                // continue saving form.
                // todo. display upload status
                axios.post('https://ct-zoomies-backend.herokuapp.com/entries',{
                    "name" : name,
                    "category" : category,
                    "email" : email,
                    "areaCode" : areaCode,
                    "phone" : phone,
                    "petname1" : petname1,
                    "petname2" : petname2,
                    "petname3" : petname3,
                    "petname4" : petname4,
                    "petname5" : petname5,
                    "upload" : data.location,
                    "terms" : terms
                },{
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(res =>{
                    const { status } = res;
                    if (status === 200) {
                        this.props.history.push("/success?email="+email);
                    }
                }).catch(err => {

                })
              });
              // .catch(this.uploadError);
        }
    }

    // uploadError = err => {
 

    componentDidMount(){

    }

    render(){

      const { modalShow, setModalShow } = this.state;
      const { email, email_confirm, name, nameError, emailError, email_confirmError, phone, phoneError, areaCode, areaCodeError, petname1, petname1Error, petname2, petname2Error, petname3, petname3Error, petname4, petname4Error, petname5, petname5Error, upload, uploadError, terms, termsError, submissionInProgress } = this.state;
      const hasError = (nameError || emailError || email_confirmError || phoneError || areaCodeError || petname1Error || petname2Error || petname3Error || petname4Error || petname5Error || uploadError || termsError ) && true;
      const category = this.props.match.params.name; // pulled from router.
      const { fileName } = this.state;
      let file = null;

      file = fileName 
        ? ( <div className="upload-wrap"><span>{fileName}</span><ClearIcon onClick={this.removeFile} /></div> ) 
        : ( <span> </span> );
        
      return(
        <Fragment>
          <FullContestRules
            show={this.state.modalShow}
            onHide={() => this.setState({modalShow:false})}
          />

          <div className="header-text-wrap text-center">
            <EntryCategories /> 
          </div>
          
          <section id="Form" className="form-wrapper">
            <Container className="form">
              <h2 className="section-title">information</h2>
          <Form onSubmit={this.handleSubmit} noValidate>
            <Form.Control
                  name="category"
                  value={category}
                  type="hidden"
                />
            <Form.Row>
              <Form.Group as={Col} controlId="validationCustom01">
                <Form.Label>full name:</Form.Label>
                <Form.Control
                  onChange={this.inputHandleChange}
                  isInvalid={!!nameError}
                  name="name"
                  value={name}
                  type="input"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  This field cannot be empty
                </Form.Control.Feedback>  
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="validationCustom02">
                <Form.Label>Email address:</Form.Label>
                <Form.Control
                  onChange={this.inputHandleChange}
                  isInvalid={!!emailError}
                  name="email"
                  value={email}
                  required
                  type="email"
                />
                <Form.Control.Feedback type="invalid">
                  You must enter a valid email.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="validationCustom02a">
                <Form.Label>Confirm your Email address:</Form.Label>
                <Form.Control
                  onChange={this.inputHandleChange}
                  isInvalid={!!email_confirmError}
                  name="email_confirm"
                  value={email_confirm}
                  required
                  type="email"
                />
                <Form.Control.Feedback type="invalid">
                  Emails must match.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="validationCustomPhone">
                <Form.Label>phone number:</Form.Label>
                  <div className="phone-wrap">
                    <span className="area-code form-control">+1</span>
                    <Form.Control
                      onChange={this.inputHandleChange}
                      isInvalid={!!phoneError}
                      name="phone"
                      value={phone}
                      type="input"
                      // pattern="^[0-9-\s()]*$"
                      required
                    />
                    <Form.Control.Feedback type="invalid" className="pet-feedback">
                    You must enter a valid phone number.
                  </Form.Control.Feedback>
                  </div>
                  
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="validationCustom03">
                <Form.Label>pet name(s):</Form.Label>
                  <DynamicInput
                    onChangeHandler={this.inputHandleChange}
                    errors={[petname1Error,petname2Error,petname3Error,petname4Error,petname5Error]}
                    name="petname1"
                    values={petname1,petname2,petname3,petname4,petname5}
                    type="input"
                    ref={this.namesRef}
                    required 
                  />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} className="mx-auto mb-2 file-upload">
                <Form.File 
                  type="file"
                  accept=".gif,.jpg,.jpeg,.png,.mp4,.mov"
                  isInvalid={!!uploadError}
                  name="upload"
                  value={upload}
                  required
                  id="upload-file"
                  label="upload video or photo"
                  custom
                  feedback="You must submit a file."
                  ref={this.fileRef}
                  onChange={ (event) => this.inputHandleChange(event) }
                />
                <Form.Label htmlFor="file" className="file-limit">50MB LIMIT</Form.Label>
                <Form.Label id="size"></Form.Label>
                <Form.Label className="uploaded-name">{file}</Form.Label>
              </Form.Group>
            </Form.Row>  
            <Form.Row>
              <Form.Group as={Col} className="text-center terms" controlId="validationCustom05">
                <Form.Check>
                  <Form.Check.Input 
                    onChange={this.inputHandleChange}
                    isInvalid={!!termsError}
                    name="terms"
                    value={terms}
                    required
                    type='checkbox' />
                  <Form.Check.Label>I HAVE READ AND AGREE TO THE <a onClick={() => this.setState({modalShow:true})} className="text-link">CONTEST RULES</a></Form.Check.Label>
                  <Form.Control.Feedback type="invalid">You must agree to terms & conditions</Form.Control.Feedback>
                </Form.Check>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} className="text-center px-4 my-5">
                <Button type="submit" variant="primary" disabled={hasError||submissionInProgress}>
                  {submissionInProgress ? <img src="/img/loading-tiny.gif" alt="submitting..." />: <span>Submit</span>}
                </Button>
              </Form.Group>
            </Form.Row>
            
          </Form>
        </Container>
      </section> 
      <Footer />
    
    </Fragment>
      );
    }
}

export default Submit;
