import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Footer } from '../component'
import { withRouter } from "react-router";


class SubmitSuccess extends Component{

    componentDidMount(){
        
    }

    render(){
        const qstring = this.props.location.search;
        let pemail = '';
        if (qstring){
            const params = qstring.substr(1).split('=');
            if (params[0]=="email"){
                pemail = params[1];
            }
        }
        return(
            <Fragment>
                <div className="header-text-wrap text-center">
                   <Container>
                       <h2 className="section-title">Thank you</h2>
                    </Container>
                </div>
                <Container>
                    <div id="SubmitSuccess" className="success-wrap my-5">
                        <div className="text-left">
                            <a href="/home"><img src="/img/white-close.svg" alt="close icon" /></a>
                        </div>
                        <div>
                            <h1 className="py-4">this calls for a treat!</h1>
                        </div>    
                        <div>
                            <p>Your entry has been successfully submitted. We’ve also sent confirmation to: <br /><b/><br />
                            {pemail}</p>
                            
                        </div>
                        <div>
                            <p>Check out the Zoomies Awards on<br />
                            OCT, 7, 2021, at 7:00 PM EST.<br />
                            Follow Canadian Tire on Instagram</p>
                        </div>
                        <div>
                            <a href="https://www.instagram.com/canadiantire/?hl=en" target="_blank"><img src="/img/instagram-modal.svg" alt="Instagram logo" /></a>
                        </div>
                    </div> 
                </Container>
                <Footer />
            </Fragment>
          );
    }
}

export default withRouter(SubmitSuccess);