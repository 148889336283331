import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';


class PresentedBy extends Component{

    componentDidMount(){
        
    }

    render(){
      return(
        <Fragment>
            <section className="d-block d-md-none">
              <Container>
                <Row>
                  <Col className="presented mobile d-block d-md-none">
                    <span className="form-brand-title">presented by</span>
                    <img src="/img/form-brand-logo.svg" alt="Canadian Tire logo" />
                  </Col>
                </Row>
              </Container>
            </section> 
        </Fragment>
      );
    }
}

export default PresentedBy;