import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import Countdown, { zeroPad, calcTimeDelta, formatTimeDelta } from 'react-countdown';


const Completionist = () => <span>It's awards time!</span>;
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return(
      <div>
        <section className="countdown-wrap py-2">
          <div className="countdown-col days"><span>{days}</span></div>
          <div className="countdown-col hours"><span>{hours}</span></div>
          <div className="countdown-col minutes"><span>{minutes}</span></div>
          <div className="countdown-col seconds"><span>{seconds}</span></div>
        </section>
      </div>
    );
  }
};

class ContestCountdown extends Component{

    componentDidMount(){
        
    }


    render(){

        return(
            <Countdown 
                date={new Date('2021-10-07T19:00:00')}
                renderer={renderer}
            />
          );
    }
}

export default ContestCountdown;