import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Header, Submit, Welcome, Partners, Faqs, SubmitSuccess, Splash, FullLegalRules } from './component/';
import ScrollToTop from './component/ScrollToTop';
import Modal from 'react-bootstrap/Modal';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

window.onload = (event) => {

};

function LoadingModal(props) {

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div id="Loading" className="loading-wrapper">
            <Container className="loading">
                <Row>
                    <Col>
                        <img src='/img/loading.gif' className="loading-logo" alt="Presented by Canadian Tire" /> 
                    </Col>
                </Row> 
            </Container>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function App() {
    const [modalShow, setModalShow] = React.useState(false);

  return (
    <div className="App">


        <LoadingModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        />

        <div className="site-wrap">
            <div className="header text-center">
                <Container>
                    <Router>
                        <Header />
                    </Router>
                </Container>
            </div>

            <div className="content">
                <Router>
                    <ScrollToTop />
                    <Switch>
                        <Route exact path="/" component={Splash} />
                        <Route exact path="/home" component={Welcome} />
                        <Route exact path="/categories" component={Welcome} />
                        <Route exact path="/faq" component={Faqs} />
                        <Route exact path="/partners" component={Partners} />
                        <Route exact path="/contest-rules" component={FullLegalRules} />
                        {/* <Route exact path="/success" component={SubmitSuccess} /> */}
                    </Switch>
                </Router>
            </div>
        </div>
       
    </div>

  );
}

export default App;
