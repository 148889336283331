import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { LegalCopy, ContestRules, PresentedBy } from '../component';
import PinterestIcon from '@material-ui/icons/Pinterest';
import YouTubeIcon from '@material-ui/icons/YouTube';

class Footer extends Component{

    constructor(props){
        super(props);
    }
    componentDidMount(){
    }


    render(){
        const showCondensed = this.props.showlegal!=="none" && this.props.showlegal!=="full";
        const showFull = this.props.showlegal==="full";
        return(
            <Fragment>
                <div className="footer">
                    <Container>
                       <div className="footer-upper">
                            <div>
                               <span className="form-brand-title">presented by</span> 
                               <a href="https://www.canadiantire.ca/en.html" target="_blank"> 
                                    <img src="/img/brand-logo-white.svg" alt="Canadian Tire logo" className="footer-brand-logo" />
                                </a>
                            </div>  
                            <div className="footer-social">
                                <a href="https://www.facebook.com/Canadiantire/" target="_blank" className="social-link"><img src="/img/facebook-white.svg" alt="Facebook" /></a>
                                <a href="https://www.instagram.com/canadiantire/?hl=en" target="_blank" className="social-link"><img src="/img/instagram-white.svg" alt="instagram" /></a>
                                <a href="https://twitter.com/CanadianTire?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" target="_blank" className="social-link"><img src="/img/twitter-white.svg" alt="twitter" /></a> 
                                <a href="https://www.pinterest.ca/canadiantire/" target="_blank" className="social-link" alt="pinterest"><img src="/img/pinterest.svg" alt="pinterest" /></a>
                                <a href="https://www.youtube.com/canadiantire" target="_blank" className="social-link"><img src="/img/youtube.svg" alt="youtube" /></a>
                           </div>
                        </div> 
                    </Container>
                </div>
                {showCondensed && <Container>   
                    <div className="legal-wrapper">
                        <p className="legal">
                            <b>†NO PURCHASE NECESSARY.</b> The Zoomies Awards Contest “the “<b>Contest</b>”)begins on
Tuesday, September 7, 2021 at 12:00:00 a.m. Eastern Time (“ET”) and ends on
Monday, September 20, 2021 at 11:59:59 p.m. ET (the “<b>Contest Period</b>”), after which time the Contest will be closed and no further entries shall be accepted. Contest open to legal residents of Canada (excluding Quebec); who are 18 years or older at the time of entry; and have a valid email address and phone number. Eligible entrant must submit their submission via www.thezoomies.ca (the “Website”) to be eligible to earn one (1) entry per Category entered, for a maximum of seven (7) Entries. Seven (7) Prizes available to be won during the Contest Period. Total approximate retail value of the Prizes is $17,000 CAD. Odds of being selected as a potential winner depends on the number and calibre of eligible entries received in each category. Judging is scheduled to be complete on Thursday, September 30, 2021 (the
“Selection Date”). Correct answer to mathematical skill-testing question required. Conditions/restrictions apply. See full <a href="/contest-rules" className="legal-link">Contest Rules</a> for details<br /><br />
Each Entrant is responsible for complying with all public health guidelines, protocols and legislation, as applicable.
                        </p>
                    </div>   
                </Container>}
                {showFull && <Container className="condensed">
                    <LegalCopy />
                </Container>}
            </Fragment>
      );
    }
}

export default Footer;