import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import RemoveIcon from '@material-ui/icons/Remove';

class DynamicInput extends React.Component {
  constructor(props, index) {
    super(props);
    this.state = { fields: [""] };
    this.removeFormGroup = this.removeFormGroup.bind(this, index);
    this.addNewPetName = this.addNewPetName.bind(this);
  }

  addNewPetName(event) {
    event.preventDefault();
    var max_fields = 5;
    var fieldArray = this.state.fields.length;
    if(fieldArray < max_fields) {
      fieldArray++;
      this.setState({
        fields: ["", ...this.state.fields]
      });
    }
  }

  removeFormGroup(index) {
    let fields = this.state.fields.splice(index);
    fields.splice(index, 1);
    this.setState({
      fields
    });
  }

  formGroupInput(index, event) {
    let fields = [...this.state.fields];
    fields[index] = event.target.value;
    this.setState({ fields });
  }

  render() {
    const isFormGroupDeletionAllowed =
      this.state.fields.length > 1 ? true : false;
    return (
      
      <div className="dynamicInput">
        {this.state.fields.map((value, index) => (
          <FormGroup
            inputChange={this.props.onChangeHandler}
            buttonClick={this.removeFormGroup}
            buttonDisabled={index === 0 ? !isFormGroupDeletionAllowed : undefined}
            error={this.props.errors[index]}
            value={this.props.values[index]}
            index={index}
            key={index}
          />
        ))}
        <Button 
          className="btn-text-link dynamicInput-add" 
          onClick={this.addNewPetName}
          type="ghost">
          add another name
        </Button>
      </div>
    );
  }
}

function FormGroup(props) {
  return (
    <Form.Row className="dynamicInput-item">
      <Form.Group as={Col} controlId="validationCustom03">
          <Form.Control
            name={"petname"+(props.index+1)}
            isInvalid={!!props.error}
            value={props.value}
            type="input"
            required
            className="dynamicInput-item"
            onChange={props.inputChange}
          />
          <button
            className="dynamicInput-button"
            type="button"
            onClick={props.buttonClick}
            disabled={props.buttonDisabled}
            tabIndex="-1"
            aria-label="Remove Button"
          />
          <Form.Control.Feedback type="invalid">
            This field cannot be empty.
          </Form.Control.Feedback>
      </Form.Group>
    </Form.Row>
  );
}

export default DynamicInput;

