import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { ContestCountdown } from '../component';
import cat from '../assets/logo-cat.svg';
import dog from '../assets/logo-dog.svg';

const awards = [
  cat,
  dog
];

class Splash extends Component{

    componentDidMount(){
        
    }

    render(){
        const award = awards[Math.floor(Math.random()*awards.length)];
        return(
            <Fragment>
                <div id="Splash" className="splash-wrapper">
                    <div className="splash">
                        <Container>
                            <Row className="my-4">
                                <Col>
                                    <img src={award} className="loading-logo" alt="Presented by Canadian Tire" /> 
                                </Col>
                            </Row> 
                            <Row className="my-4">
                                <Col className="presented-wrap">
                                    <h2 className="presented">presented by</h2>
                                    <img src="/img/loading-ct-logo.svg" alt="Presented by Canadian Tire" /> 
                                </Col>
                            </Row> 
                            <Row>
                                <Col className="splash-info mt-2 mb-2">
                                <div style={{ padding: "56.25% 0 0 0", position:"relative" }}><iframe src="https://player.vimeo.com/video/617292585?h=5fe3ccd5b6&title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{ position:"absolute", top:0, left:0, width:"100%", height:"100%" }}></iframe></div>
                                <div>If you’re experiencing technically difficulty, view the event <a href="https://vimeo.com/617292585" target="_blank" class="inline-link">here</a></div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="my-4">
                                    <Button href="/home" variant="secondary">enter site</Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>

            </Fragment>
          );
    }
}

export default Splash;