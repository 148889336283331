import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Footer } from '../component';

class Partners extends Component{

    componentDidMount(){
        
    }

    render(){
        return(
            <Fragment>
                <div className="header-text-wrap text-center">
                   <Container>
                       <h2 className="section-title partner">Partners</h2>
                    </Container>
                </div>
                <Container className="py-4">
                    <Row>
                      <Col className="host">
                          <img src='/img/partner-avatar.svg' alt="zoomies parters" /> 
                          <div>
                            <h2 className="section-title partner">Canadian Tire’s Gary</h2>
                            <p>Over the years, you’ve seen Gary hang upside down, drive an ice truck, report on a backyard obstacle course, and even direct a Father’s Day musical, all with the goal of helping Canadians pick great products from Canadian Tire. With his love for animals, Gary is excited bring his expertise to the first ever Zoomies Awards.</p>   
                          </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="host">
                          <img src='/img/host-avatar.png' alt="zoomies parters" /> 
                          <div>
                            <h2 className="section-title partner">Kayla-Marie Tracy</h2>
                            <p>As a former professional volleyball player and reporter for Ticats TV, Kayla-Marie is quick on her feet. Now, her charisma and passion can be seen on CP24’s Breaking News. Outside of work, Kayla is a lover of all animals. She has two dogs, Solomon & Ivy, two adorable American Akitas, and she’s looking forward to seeing your pet nominations for The Zoomies Awards.</p>   
                          </div>
                      </Col>
                    </Row>
                    <h2 className="section-title partner my-4">Partners</h2>
                    <div className="partner-wrap my-3">
                       <Row>
                            <Col>
                                
                                <div className="text-center partners mb-6">
                                    <div className="partner-img-wrap">
                                      <a href="https://ontariospca.ca/" target="_blank">
                                        <img src='/img/SPCA.svg' alt="zoomies parters" /> 
                                      </a>
                                    </div>
                                    <div>
                                     <h2 className="section-title partner">Ontario SPCA and Humane Society</h2>
                                    <p>Proudly sponsoring the Top Dog award, the Ontario SPCA and Humane Society is a registered charity, established in 1873. The Society and its network of communities facilitate and provide for province-wide leadership on matters relating to the prevention of cruelty to animals and the promotion of animal well-being. For more information on what they offer, you can visit <a href="https://ontariospca.ca/" target="_blank" className="text-link">ontariospca.ca</a></p>   
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="text-center partners mb-6">
                                    <div className="partner-img-wrap">
                                      <a href="http://www.hsicanada.ca" target="_blank">
                                        <img src='/img/humane-society.svg' alt="zoomies parters" /> 
                                      </a>
                                    </div>
                                    <div>
                                       <h2 className="section-title partner">Humane Society International/Canada</h2>
                                    <p>Humane Society International/Canada is a leading force for animal protection, with active programs in companion animals, wildlife and habitat protection, marine mammal preservation, farm animal welfare and animals in research. HSI/Canada is proud to be a part of Humane Society International which, together with its partners, constitutes one of the world's largest animal protection organizations. Celebrating animals and confronting cruelty worldwide - on the web at <a href="http://www.hsicanada.ca/" target="_blank" className="text-link">www.hsicanada.ca</a> </p> 
                                    </div>
                                    
                                </div>
                            </Col>
                        </Row> 
                    </div>
                    <Row>
                        <Col className="text-center py-4 my-4">
                            <Link to="/home" className="btn">return to categories</Link>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </Fragment>
          );
    }
}

export default Partners;