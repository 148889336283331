import React, { Component, Fragment } from 'react';
import { Nav, NavDropdown, Navbar, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import cat from '../assets/logo-cat.svg';
import dog from '../assets/logo-dog.svg';
import MenuIcon from '@material-ui/icons/Menu';

const awards = [
  cat,
  dog
];

class Header extends Component{

    componentDidMount(){
        
    }

    render(){
      const award = awards[Math.floor(Math.random()*awards.length)];
      return(
        <Fragment>
          <Navbar collapseOnSelect expand="xxl">
            <Navbar.Toggle aria-controls="responsive-navbar-nav">
              <MenuIcon />
            </Navbar.Toggle>
            <Navbar.Brand href="/home"><img src={award} className="header-logo" alt="The Zoomies Awards" /></Navbar.Brand>
            <Navbar.Brand href="/home" className="brand-logo"><img src="/img/nav-brand.svg" alt="Canadian Tire" /></Navbar.Brand>
            <Navbar.Collapse id="responsive-navbar-nav">
             <Nav className="mr-auto">
                <Nav.Link href="/home">categories</Nav.Link>
                <Nav.Link href="/faq">FAQ</Nav.Link>
                <Nav.Link href="/partners">partners</Nav.Link>
                <Nav.Link href="/contest-rules">legal</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Fragment>
      );
    }
}

export default withRouter(Header);