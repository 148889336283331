import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { LegalCopy, Footer, ContestCountdown } from '../component';


class FullLegalRules extends Component{

    componentDidMount(){
        
    }


    render(){
        return(
            <Fragment>
                <div className="header-text-wrap text-center pb-4">
                   <Container>
                        {/* <div className="countdown pb-2">
                          <h2 className="section-title">the show starts in</h2>
                          <ContestCountdown />
                        </div> */}
                        <h2 className="section-title">Contest rules and regulations</h2>
                        
                        <Button href="/" variant="secondary" className="mt-3 mb-5">Watch the event</Button>
                    </Container>
                </div>
                <section className="contest-rules-wrapper py-4">
                    <Container>
                       <Row className="legal-wrapper">
                            <Col>
                                <LegalCopy />
                            </Col>
                        </Row>   
                    </Container>
                    <Footer showlegal="none" />
                </section>
            </Fragment>
          );
    }
}

export default FullLegalRules;