import React, { Component, Fragment, useContext } from 'react';
import { Container, Row, Col, Accordion, Card, AccordionContext, useAccordionToggle, Form, FormControl, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Footer } from '../component';
import SearchIcon from '@material-ui/icons/Search';


function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    const isCurrentEventKey = currentEventKey === eventKey;
  
    return (
      <Card.Header
        className={ isCurrentEventKey ? 'active' : '' }
        onClick={decoratedOnClick}
      >
        {children}
      </Card.Header>
    );
  }

function searchPage() {
    var input, filter, list, card, cardBody, cardTitle, i, txtValue, questionValue, answerValue,
    input = document.getElementById("sortInput");
    filter = input.value.toUpperCase();
    list = document.getElementById("sortList");
    card = list.getElementsByClassName("card");

    for (i = 0; i < card.length; i++) {
        cardBody = card[i].getElementsByClassName("card-body")[0];
        cardTitle = card[i].getElementsByClassName("accordion-title")[0];
        answerValue = cardBody.innerText;
        questionValue = cardTitle.innerText;
        txtValue = questionValue || answerValue;

        if (questionValue.toUpperCase().indexOf(filter) > -1 || answerValue.toUpperCase().indexOf(filter)  > -1 ) {
            card[i].style.display = "";
        } else if (txtValue.toUpperCase().indexOf(filter) === -1)  {
           card[i].style.display = "none"; 
            
        } else  {
            card[i].style.display = "none";
        }
    }
}  

class Faqs extends Component{

    componentDidMount(){
        
    }

    render(){
        return(
            <Fragment>
                <div className="header-text-wrap faq text-center">
                   <Container>
                       <h2 className="section-title">FAQ</h2>
                       <section className="search-wrap py-2">
                            <Form className="search">
                              <FormControl id="sortInput" onKeyUp={searchPage} type="text" placeholder="Search" className="mr-sm-2"  />
                              <Button aria-label="search button">
                                  <SearchIcon />
                              </Button>
                            </Form>  
                        </section>
                    </Container>
                </div>
               
                <Container className="faq">
                    <Row>
                    <Col>
                        <Accordion id="sortList">
                            <section>
                            <Card>
                                {/*<h2 className="section-title secondary">Group 1</h2>*/}
                                <ContextAwareToggle eventKey="0">
                                    <span className="accordion-title">Q: What are The Zoomies Awards?</span>
                                </ContextAwareToggle>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    A: The Zoomies Awards is an awards contest to recognize Canadian’s cats and dogs contributions to wellness and happiness over the last year through multiple fun categories.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <ContextAwareToggle eventKey="1">
                                    <span className="accordion-title">Q: Who is eligible to participate in the contest?</span>
                                </ContextAwareToggle>
                                <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                A: Residents of Canada (excluding Quebec), who are 18 years of age or older at the time of entry, are eligible to submit a video or photo nominating their pet for The Zoomies Awards. *See contest rules for more details.
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <ContextAwareToggle eventKey="2">
                                    <span className="accordion-title">Q: How do I know if my pet is eligible for the contest?</span>
                                </ContextAwareToggle>
                                <Accordion.Collapse eventKey="2">
                                <Card.Body>
A: The nominated pet must be a cat or dog, belong to a resident of Canada, and/or be a member of their immediate family.                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </section>
                        <section>
                            <Card>
                                {/*<h2 className="section-title secondary">Group 2</h2>*/}  
                                <ContextAwareToggle eventKey="3">
                                    <span className="accordion-title">Q: When is the deadline for nominations?</span>
                                </ContextAwareToggle>
                                <Accordion.Collapse eventKey="3">
                                <Card.Body>
A: The deadline for nominations is Monday, September 20 at 11:59 p.m. ET.                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <ContextAwareToggle eventKey="4">
                                    <span className="accordion-title">Q: What format does my nomination need to be?</span>
                                </ContextAwareToggle>
                                <Accordion.Collapse eventKey="4">
                                <Card.Body>A: For videos, please submit an MP4 or MOV file. For photos, please submit JPEG, PNG, or GIF. Maximum file size is 50mb.                                
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </section>
                        <section>
                            <Card>
                                {/*<h2 className="section-title secondary">Group 3</h2>*/}
                                <ContextAwareToggle eventKey="5">
                                    <span className="accordion-title">Q: Can I submit my pet for multiple categories?</span>
                                </ContextAwareToggle>
                                <Accordion.Collapse eventKey="5">
                                <Card.Body>A: Yes. To submit your pet for multiple categories, simply complete one nomination form per category. Please note, you may only submit one nomination per pet, per category.                                
</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <ContextAwareToggle eventKey="6">
                                    <span className="accordion-title">Q: What kind of prizes will be awarded to the winning nominations?</span>
                                </ContextAwareToggle>
                                <Accordion.Collapse eventKey="6">
                                <Card.Body>A: One winner will be selected from each category of The Zoomies Awards. Each
winner will receive a $1000 Canadian Tire gift card. The Top Dog and Top Cat Prize,
respectively, will be awarded $5,000 Canadian Tire Gift Card. There is a limit of one
prize per household.
</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <ContextAwareToggle eventKey="7">
                                    <span className="accordion-title">Q: How will the finalists be selected?</span>
                                </ContextAwareToggle>
                                <Accordion.Collapse eventKey="7">
                                <Card.Body>
A: Finalists will be selected by our judges. Using a point system, nominations will be assessed on category relevance, humour, cuteness, and uniqueness.                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <ContextAwareToggle eventKey="8">
                                    <span className="accordion-title">Q: How will I know if I am selected as a finalist?</span>
                                </ContextAwareToggle>
                                <Accordion.Collapse eventKey="8">
                                <Card.Body>
A: Finalists will be notified via email by September 30, 2021.                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <ContextAwareToggle eventKey="9">
                                    <span className="accordion-title">Q: When will winners be announced?</span>
                                </ContextAwareToggle>
                                <Accordion.Collapse eventKey="9">
                                <Card.Body>A: Winners will be announced publicly during The Zoomies Award show on Thursday, October 7, 2021.                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <ContextAwareToggle eventKey="10">
                                    <span className="accordion-title">Q: Why do you want my content for marketing/communication purposes?</span>
                                </ContextAwareToggle>
                                <Accordion.Collapse eventKey="10">
                                <Card.Body>A: Canadian Tire would like to review submissions for potential use in future marketing and communications materials such as digital content, social media, etc. Entry into The Zoomies Awards automatically implies consent to use your content, image, or likeness for external marketing communications purposes.                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </section>
                            
                        </Accordion> 
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center py-4 my-4">
                        <Link to="/home" className="btn">return to categories</Link>
                    </Col>
                </Row>
                </Container>
                <Footer />
            </Fragment>
          );
    }
}

export default Faqs;