import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Categories, Footer } from './';
import { BrowserRouter as Router} from 'react-router-dom';


class Welcome extends Component{

    componentDidMount(){
        
    }


    render(){

        return(
            <Fragment>
                <Categories /> 
                <Footer showlegal="full" />  
            </Fragment>
          );
    }
}

export default Welcome;