import React, { Component, Fragment } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useParams } from "react-router";


const data = [
  {
    name: 'runway ready',
  },
  {
    name: 'best in slow-mo',
  },
  {
    name: 'top snoozer',
  },
  {
    name: 'co-worker of the year',
  },
  {
    name: 'if i fits, i sits',
  },
  {
    name: 'treat master',
  },
  {
    name: 'fastest zoomies',
  }
];

function CategoryName() {
  let {name} = useParams();
  return <span className="category-name">{name}</span>;
}

function toggleDropdown() {
  document.getElementById("dropdown").classList.toggle("show");
  document.getElementById("dropdown-wrap").classList.toggle("show-wrap");

  if (document.getElementById("dropdown").classList.contains('show')){
    setTimeout(function(){document.addEventListener('click',collapseDropdown)},100);
  }
}
function collapseDropdown(){
  document.removeEventListener('click',collapseDropdown);
  document.getElementById("dropdown").classList.toggle("show");
  document.getElementById("dropdown-wrap").classList.toggle("show-wrap");
}

class EntryCategories extends Component{

    componentDidMount(){
        
    }

    render(){
      return(
          <Fragment>
            <section className="category-list">
              <Container>
                <h2 className="section-title">entry submission</h2>
                <div id="dropdown-wrap" className="mx-auto">
                    <Button onClick={toggleDropdown} className="btn-dropdown" >
                      <CategoryName />
                      <ArrowDropDownIcon />
                    </Button>
                    <div id="dropdown" className="dropdown-content">
                      { data.map(
                        ({name}, index, ) =>
                        <a href={"/submit/"+name} className="entry-category" key={index}>{name}</a>  
                      )}
                    </div>  
                </div>
              </Container>
            </section> 
          </Fragment>
        );
    }
}

export default EntryCategories;